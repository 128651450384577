export {};

// Extend the Date prototype to add custom methods
declare global {
    interface Date {
      getNextDay(): Date;
      getDateWithoutTime(): Date;
    }
}
  
  // Adding the `getNextDay` method to the Date prototype
Date.prototype.getNextDay = function (): Date {
    const nextDay = new Date(this);
    nextDay.setDate(this.getDate() + 1);
    return nextDay;
};
  
  // Adding the `getDateWithoutTime` method to the Date prototype
Date.prototype.getDateWithoutTime = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate());
};