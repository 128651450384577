<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
    <c-container breakpoint="md">
      <c-row class="justify-content-center">
        <c-col lg="10" xl="8">
          <c-card-group>
            <c-card class="p-4">
              <c-card-body>
                <form (submit)="submit()" cForm>
                  <h1>Login</h1>
                  <p class="text-body-secondary">Sign In to your account</p>
                  <c-input-group class="mb-3">
                    <span cInputGroupText>
                      <svg cIcon name="cilUser"></svg>
                    </span>
                    <input autoComplete="username" [(ngModel)]="username" name="username" cFormControl placeholder="Username" />
                  </c-input-group>
                  <c-input-group class="mb-4">
                    <span cInputGroupText>
                      <svg cIcon name="cilLockLocked"></svg>
                    </span>
                    <input
                      autoComplete="current-password"
                      cFormControl
                      [(ngModel)]="password"
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                  </c-input-group>
                  <!-- <sol-input type="password"/> -->
                  <c-row>
                    
                    <c-col xs="12">
                      <div class="d-flex justify-content-end">
                        <sol-button role="submit" (onclick)="submit()" [options]="{isLoading:isLoggingIn}">Login</sol-button>
                        <input type="submit"  hidden />

                      </div>
                    </c-col>
   
                  </c-row>
                </form>
              </c-card-body>
            </c-card>
  
          </c-card-group>
        </c-col>
      </c-row>
    </c-container>
  </div>

 
  