import { Component } from "@angular/core";
import { NgStyle } from "@angular/common";
import { IconDirective } from "@coreui/icons-angular";
import {
  ContainerComponent,
  RowComponent,
  ColComponent,
  CardGroupComponent,
  TextColorDirective,
  CardComponent,
  CardBodyComponent,
  FormDirective,
  InputGroupComponent,
  InputGroupTextDirective,
  FormControlDirective,
  ButtonDirective,
} from "@coreui/angular-pro";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Router } from "@angular/router";
import { InputComponent } from "../../../components/ui/input/input.component";
import { FormsModule } from "@angular/forms";
import { ButtonComponent } from "../../../components/ui/button/button.component";
import { ToasterService } from "../../../services/toast/toast.service";

@Component({
  selector: "sol-login",
  standalone: true,
  imports: [
    ContainerComponent,
    RowComponent,
    InputComponent,
    ColComponent,
    CardGroupComponent,
    TextColorDirective,
    CardComponent,
    CardBodyComponent,
    FormDirective,
    InputGroupComponent,
    InputGroupTextDirective,
    IconDirective,
    FormControlDirective,
    ButtonDirective,
    NgStyle,
    ButtonComponent,
    FormsModule,
  ],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent {
  username: string = "";
  password: string = "";
  message: string = "";
  isLoggingIn: boolean = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
   private _toasterService: ToasterService
  ) {}
  ngOnInit(): void {}

  submit() {
    this.isLoggingIn = true;
    this.authenticationService
      .login(this.username, this.password)
      .subscribe((res) => {
        this.isLoggingIn = false;
        console.log(res)
        if(res.errorCode===31) {
          this._toasterService.showError(`Username and/or password are incorrect`)
          this.authenticationService.isLoggedin = false;
          return;
        }
        this.authenticationService.isLoggedin = true;
        this.router.navigateByUrl("/home");
        this._toasterService.showSuccess("Logged in successfully !",5000)
      },(err)=>{
        console.log(err)
        this._toasterService.showError(`Username and/or password are incorrect`)
        this.authenticationService.isLoggedin = false;
        this.isLoggingIn = !this.isLoggingIn;
        return;
      });
  }
}
