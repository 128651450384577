<sol-toast></sol-toast>
<div *ngIf="isLoggedIn">
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed bg-dark-gradient border-end"
  colorScheme="dark"
  id="sidebar1"
  visible
>

  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand [routerLink]="[]">
     <img class="logo-size" src="./../assets/img/Logo.png" alt="">
    </c-sidebar-brand>
    <button cSidebarToggle="sidebar1"
    cButtonClose
    class="d-lg-none"
    dark
    toggle="visible"
    aria-label="Toggle sidebar">
</button>
<!-- <button cSidebarToggler="sidebar1" toggle="unfoldable" aria-label="Toggle sidebar fold"></button> -->
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="filteredNavItems" dropdownMode="path" compact />
  </ng-scrollbar>
 
  <!-- <button type="button" class="btn btn-primary btn-bottom btn-full-width btn-margin"(click)="onLogout()">Log out</button> -->
</c-sidebar>


<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->


  <sol-header [loggedInAs]="loggedinAs" (logout)="onLogout()" [sidebarId]="sidebarId"  class="mb-4 d-print-none header header-sticky p-0 shadow-sm"/>

  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container [fluid]="true" class="h-auto px-5">
      <!-- <sol-breadcrumb/>  -->
      <router-outlet />
    </c-container>
    <br/>
  </div>
  <!--app footer-->
  <sol-footer />
</div>
</div>
<div *ngIf="!isLoggedIn">
<sol-login></sol-login>
</div>

<!--aside--> 
 
