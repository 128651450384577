  
<div class="position-fixed top-0 start-0" style="width: 100%; height: 100%; pointer-events: none; z-index: 1050;">
    
    <c-toaster *ngIf="groupedToasts()['top-start']?.length" placement="top-start" position="absolute">
      <ng-container *ngFor="let toast of groupedToasts()['top-start']">
        <c-toast [autohide]="true" [delay]="toast.delay || 5000" class="text-white m-2" visible (hidden)="removeToast(toast)" color="{{ toast.type }}">
          <c-toast-header *ngIf="toast.title">
            <strong class="me-auto">{{ toast.title }}</strong>
            <small>Just now</small>
          </c-toast-header>
          <c-toast-body>
            <c-container>
                <c-row class="justify-content-between px-2">
                    <c-col [cCol]="11">{{ toast.message }}</c-col>
                    <c-col [cCol]="1"><button  *ngIf="!toast.title" type="button" class="btn-close btn-close-white text-end" aria-label="Close" (click)="removeToast(toast)"></button></c-col>
                </c-row>
            </c-container>
          </c-toast-body>
        </c-toast>
      </ng-container>
    </c-toaster>
  
    <c-toaster *ngIf="groupedToasts()['top-center']?.length" placement="top-center" position="absolute">
      <ng-container *ngFor="let toast of groupedToasts()['top-center']">
        <c-toast [autohide]="true" [delay]="toast.delay || 5000" class="text-white m-2" visible (hidden)="removeToast(toast)" color="{{ toast.type }}">
          <c-toast-header *ngIf="toast.title">
            <strong class="me-auto">{{ toast.title }}</strong>
            <small>Just now</small>
          </c-toast-header>
          <c-toast-body>
            <c-container>
                <c-row class="justify-content-between px-2">
                    <c-col [cCol]="11">{{ toast.message }}</c-col>
                    <c-col [cCol]="1"><button  *ngIf="!toast.title" type="button" class="btn-close btn-close-white text-end" aria-label="Close" (click)="removeToast(toast)"></button></c-col>
                </c-row>
            </c-container>
          </c-toast-body>
        </c-toast>
      </ng-container>
    </c-toaster>
  
    <c-toaster *ngIf="groupedToasts()['top-end']?.length" placement="top-end" position="absolute">
      <ng-container *ngFor="let toast of groupedToasts()['top-end']">
        <c-toast [autohide]="true" [delay]="toast.delay || 5000" class="text-white m-2" visible (hidden)="removeToast(toast)" color="{{ toast.type }}">
          <c-toast-header *ngIf="toast.title">
            <strong class="me-auto">{{ toast.title }}</strong>
            <small>Just now</small>
          </c-toast-header>
          <c-toast-body>
            
            <c-container>
                <c-row class="justify-content-between px-2">
                    <c-col [cCol]="11">{{ toast.message }}</c-col>
                    <c-col [cCol]="1"><button  *ngIf="!toast.title" type="button" class="btn-close btn-close-white text-end" aria-label="Close" (click)="removeToast(toast)"></button></c-col>
                </c-row>
            </c-container>
          </c-toast-body>
        </c-toast>
      </ng-container>
    </c-toaster>
    <!-- is not working for now so comment it out -->
    <!-- <c-toaster *ngIf="groupedToasts()['bottom-start']?.length" placement="bottom-start" position="absolute">
      <ng-container *ngFor="let toast of groupedToasts()['bottom-start']">
        <c-toast [autohide]="true" [delay]="toast.delay || 5000" class="text-white mt-2" visible (hidden)="removeToast(toast)" color="{{ toast.type }}">
          <c-toast-header *ngIf="toast.title">
            <strong class="me-auto">{{ toast.title }}</strong>
            <small>Just now</small>
          </c-toast-header>
          <c-toast-body>
            {{ toast.message }}
          </c-toast-body>
        </c-toast>
      </ng-container>
    </c-toaster>
  
    <c-toaster *ngIf="groupedToasts()['bottom-center']?.length" placement="bottom-center" position="absolute">
      <ng-container *ngFor="let toast of groupedToasts()['bottom-center']">
        <c-toast [autohide]="true" [delay]="toast.delay || 5000" class="text-white mt-2" visible (hidden)="removeToast(toast)" color="{{ toast.type }}">
          <c-toast-header *ngIf="toast.title">
            <strong class="me-auto">{{ toast.title }}</strong>
            <small>Just now</small>
          </c-toast-header>
          <c-toast-body>
            {{ toast.message }}
          </c-toast-body>
        </c-toast>
      </ng-container>
    </c-toaster>
  
    <c-toaster *ngIf="groupedToasts()['bottom-end']?.length" placement="bottom-end" position="absolute">
      <ng-container *ngFor="let toast of groupedToasts()['bottom-end']">
        <c-toast [autohide]="true" [delay]="toast.delay || 5000" class="text-white mt-2" visible (hidden)="removeToast(toast)" color="{{ toast.type }}">
          <c-toast-header *ngIf="toast.title">
            <strong class="me-auto">{{ toast.title }}</strong>
            <small>Just now</small>
          </c-toast-header>
          <c-toast-body>
            {{ toast.message }}
          </c-toast-body>
        </c-toast>
      </ng-container>
    </c-toaster> -->
</div>
  