import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from "@angular/router";
import { DropdownModule, SidebarModule } from "@coreui/angular-pro";
import { IconSetService } from "@coreui/icons-angular";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { HttpInterceptor } from "./shared/interceptors/http/http.interceptor";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { BlobInterceptor } from "./shared/interceptors/http/blob/blob.interceptor";
import { QuillModule } from "ngx-quill";

export const appConfig: ApplicationConfig = {
  providers: [
    provideCharts(withDefaultRegisterables()),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: "reload",
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: "top",
        anchorScrolling: "enabled",
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withHashLocation()
    ),
    importProvidersFrom(SidebarModule, DropdownModule, QuillModule.forRoot()),
    IconSetService,
    provideAnimations(),
    provideHttpClient(withInterceptors([HttpInterceptor, BlobInterceptor])),
  ],
};
