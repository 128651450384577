import { Component, effect, signal, computed, untracked } from '@angular/core';
import { ColComponent, ContainerComponent, RowComponent, ToastModule } from '@coreui/angular-pro';
import { Toast, ToasterService, ToastPlacement } from '../../../services/toast/toast.service';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
type GroupedToasts = {
  [key in ToastPlacement]?: Toast[]
}
@Component({
  selector: 'sol-toast',
  standalone: true,
  imports: [ToastModule, TitleCasePipe, NgFor, NgIf, ContainerComponent, RowComponent, ColComponent],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  toasts = signal<Toast[]>([]);
  groupedToasts = computed<GroupedToasts>(() => {
    const grouped = this.toasts().reduce((acc, toast) => {
      const placement = toast.placement || 'top-end';
      if (!acc[placement]) {
        acc[placement] = [];
      }
      acc[placement]!.push(toast);
      return acc;
    }, {} as { [key in ToastPlacement]?: Toast[] });

    return grouped
  });

  constructor(private toasterService: ToasterService) {
    effect(() => {
      const toast = toasterService.toastState()
      if(Object.keys(toast).length === 0) return
      untracked(() => this.toasts.set([...this.toasts(), toast]))
      if (toast.delay) {
        setTimeout(() => this.removeToast(toast), toast.delay);
      }
    }, { allowSignalWrites: true })
  }

  ngOnInit(): void {
  }

  removeToast(toast: Toast): void {
    this.toasts.set(this.toasts().filter(t => t !== toast));
  }
 
}
