import { FormControl, FormGroup, AbstractControl } from "@angular/forms";

// Define a type that maps the keys of a FormGroup to FormControls
type ControlsOf<T extends FormGroup> = {
  [K in keyof T["controls"]]: T["controls"][K] extends (FormControl | undefined)
    ? T["controls"][K]
    : never;
};

declare module "@angular/forms" {
  interface FormGroup {
    getFormControl<T extends FormGroup, K extends keyof ControlsOf<T>>(
      this: T,
      key: K
    ): ControlsOf<T>[K];
  }
}

FormGroup.prototype.getFormControl = function <
  T extends FormGroup,
  K extends keyof ControlsOf<T>
>(this: T, key: K) {
  const control = this.get(key as string);
  if (!control || !(control instanceof FormControl)) {
    throw new Error(
      `Control with key "${String(key)}" not found or is not a FormControl.`
    );
  }
  return control as ControlsOf<T>[K];
};
