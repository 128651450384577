import { Component, OnInit } from '@angular/core';
import { FooterComponent as CoreUiFooterComponent } from '@coreui/angular-pro';

@Component({
  selector: 'sol-footer',
  standalone: true,
 
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent  extends CoreUiFooterComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
  }
  
}
