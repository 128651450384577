import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpRequest, HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { LocalStorageService } from '../../../services/localStorage/local-storage.service';

export const HttpInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const authenticationService = inject(AuthenticationService);
  const localStorageService = inject(LocalStorageService);

  const token = localStorageService.getAccessToken();
  let authReq = req;

  if (token && !req.headers.has('Authorization')) {
    authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        if (error.error.errorCode === 55) {
          console.error('Error code 55: Refreshing token', error);
          
          return authenticationService.refreshToken().pipe(
            switchMap((response) => {
              localStorageService.storeTokens({ token: response.data.token, refreshToken: response.data.refreshToken });
              const newAuthReq = req.clone({
                setHeaders: {
                  'Content-Type': 'application/json; charset=utf-8',
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${response.data.token}`,
                },
              });
              return next(newAuthReq);
            }),
            catchError(err => {
              console.error('Error refreshing token', err);
              localStorageService.removeTokens();
              return throwError(() => new Error(err.message));
            })
          );
        } else if (error.error.errorCode === 54) {
          console.error('Error code 54: Refresh token not valid', error);
           
          // Add logic to log out the user, e.g., redirecting to the login page
          authenticationService.logout();
          return throwError(() => new Error('Refresh token is not valid. User logged out.'));
        } else {
          console.error('Unauthorized error', error);
          return throwError(() => new Error(error.message));
        }
      } else if (error.status === 403) {
        if (error.error.errorCode === 64) {
          console.error('Error code 64: You are blocked', error);
           
          // Add logic to log out the user, e.g., redirecting to the login page
          authenticationService.logout();
          return throwError(() => new Error('You are blocked. User logged out.'));
        }
      }
  
      return throwError(() => error);
    })
  );
};
